<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="login">
                  <h1>Cambia tu contraseña</h1>
                  <p class="text-muted">Para acceder a beneficios</p>
                  <CInput
                    placeholder="Nueva contraseña"
                    type="password"
                    autocomplete="curent-password"
                    v-model="form.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow v-if="error">
                    <CCol>
                      <CAlert color="danger" dismissible>
                        <strong>Ocurrió un error,</strong> usuario o contraseña incorrecta
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="5" class="text-left">
                      <CButton color="primary" class="px-4" type="submit" @click="send">
                        <CSpinner v-if="loading" variant="grow" size="sm"/>
                         Guardar
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody class="banner">
                <img src="img/banner_inicio.jpg" width="100%" />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { login } from '@/api/auth'


export default {
  data: () => ({
    toasts: [],
    user: {},
    form: {
      password: ''
    },
    loading: false,
    error: false,
  }),
  mounted() {
    if(this.$store.state.user.token){
      this.$router.push('/commerceInventory');
    }
  },
  methods: {
    async send() {
      let formData = new FormData();
      formData.append('password', this.form.password);
      formData.append('token', this.$route.params.id);
      await axios.post('https://backend.esd.softwarecenter.com.ec/api/users/sc_token/password',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json'
          }
        }
      ).then((response) => {
        this.$store.commit('setToken', response.data.token);
        this.$cookies.set('login_token', response.data.token);
        if(response.data.token){
          this.$store.commit('setMenu', response.data.menu);
          this.$store.commit('setRole', response.data.user.role);
          this.$store.commit('setName', response.data.user.name);
        }
        location.reload();
      })
      .catch((error) => {
        var errors = error.response.data.errors;
        if (errors == undefined) {
          var message = error.response.data.message;
          var error = {
            title: "Error al canjear licencias",
            color: "danger",
            message: message,
          };
        } else {
          var error = {};
          Object.keys(errors).forEach((key) => {
            error = {
              title: "Error al canjear licencias",
              color: "danger",
              message: errors[key][0],
            };
          });
        }
        this.toasts.push(error);
      });

    }
  },
  name: 'Scenter'
}
</script>
